const filtersFleet = () => {
  // Select the elements
  const filtersFleet = document.querySelector('.filters-fleet');
  const tablesDesktop = document.querySelectorAll('.filters-fleet__table');
  const tablesMobile = document.querySelectorAll('.filters-fleet__mobile');
  const tabsLinks = document.querySelectorAll('.tabs__list__item');
  const tabsContent = document.querySelectorAll('.filters-fleet__category');
  const btnPlus = document.querySelectorAll('.btn--plus');
  const btnMinus = document.querySelectorAll('.btn--minus');
  const btnMetric = document.querySelector('.btn--metric');
  const btnImperial = document.querySelector('.btn--imperial');
  const images = document.querySelectorAll('.filters-fleet__image');
  const lightbox = document.querySelector('.filters-fleet__lightbox');
  const lightboxImage = document.querySelector('.filters-fleet__lightbox__img');

  // Check if the parent element exists, if not, stop the function
  if (!filtersFleet) return;

  // Add event listeners to the tabs
  tabsLinks.forEach((tab) => {
    tab.addEventListener('click', () => {
      // Remove active class from all tabs
      tabsLinks.forEach((tab) => {
        tab.classList.remove('tabs__list__item--active');
      });

      // Add active class to clicked tab
      tab.classList.add('tabs__list__item--active');

      // Get the inner text from the button inside this element
      const btnText = tab
        .querySelector('button')
        .innerText.replaceAll('&', 'and')
        .replaceAll(' ', '-')
        .toLowerCase();

      // If the text matches the class of the tabsContent, show it
      tabsContent.forEach((content) => {
        if (content.classList.contains(btnText)) {
          content.classList.add('filters-fleet__category--active');
        } else {
          content.classList.remove('filters-fleet__category--active');
        }
      });
    });
  });

  // For each image, when clicked on enlarge the image and show the close button, when clicking outside the enlarged image or on the close button, make the image smaller again
  images.forEach((image) => {
    image.addEventListener('click', () => {
      // Get the thumbnail img src and alt and set it to the lightbox img src and alt
      const imageSrc = image.querySelector('img').getAttribute('src');
      const imageAlt = image.querySelector('img').getAttribute('alt');
      lightboxImage.setAttribute('src', imageSrc);
      lightboxImage.setAttribute('alt', imageAlt);

      // Show the lightbox
      lightbox.classList.add('filters-fleet__lightbox--active');
      document.body.style.overflow = 'hidden';

      // When clicking outside the lightbox image, close the lightbox
      lightbox.addEventListener('click', (e) => {
        if (!e.target.classList.contains('filters-fleet__lightbox__img')) {
          lightboxImage.setAttribute('src', '');
          lightboxImage.setAttribute('alt', '');
          lightbox.classList.remove('filters-fleet__lightbox--active');
          document.body.style.overflow = 'auto';
        }
      });
    });
  });

  // Filter the items based on the region
  const showItemsByRegion = (region) => {
    const items = document.querySelectorAll('[data-region]');

    items.forEach((item) => {
      const itemRegion = item.dataset.region;
      const showItem = itemRegion.includes(region);

      if (showItem) {
        // Delete the empty tr element with the is-hidden class that's right after the item that gets the is-hidden class
        const emptyTableRow = item.nextElementSibling;
        if (emptyTableRow && emptyTableRow.classList.contains('empty-tr')) {
          emptyTableRow.remove();
        }

        item.classList.remove('is-hidden');
      } else {
        item.classList.add('is-hidden');

        // Create an empty tr element with the is-hidden class right after every item that gets the is-hidden class
        const emptyTableRow = document.createElement(
          window.innerWidth < 992 ? 'div' : 'tr'
        );
        emptyTableRow.classList.add('is-hidden', 'empty-tr');
        item.after(emptyTableRow);
      }
    });
  };

  // Check whether the btnMetric or btnImperial doesn't have the 'btn--primary--outline' class and call the showItemsByRegion function with the correct region
  if (!btnMetric.classList.contains('btn--primary--outline')) {
    showItemsByRegion('europe');
  } else {
    showItemsByRegion('northAmerica');
  }

  // Add event listeners to the buttons
  btnPlus.forEach((btn) => {
    btn.addEventListener('click', () => {
      btn.classList.add('is-hidden');

      // Get the direct parent of the button and find the button with the class 'btn--minus' and remove the class 'is-hidden'
      btn.parentElement
        .querySelector('.btn--minus')
        .classList.remove('is-hidden');

      // Get the specific parent which has the element with the class 'filters-fleet__item__body' and add the class 'is-open'
      btn.parentElement.parentElement.parentElement
        .querySelector('.filters-fleet__item__body')
        .classList.add('is-open');
    });
  });

  btnMinus.forEach((btn) => {
    btn.addEventListener('click', () => {
      btn.classList.add('is-hidden');

      // Get the direct parent of the button and find the button with the class 'btn--plus' and remove the class 'is-hidden'
      btn.parentElement
        .querySelector('.btn--plus')
        .classList.remove('is-hidden');

      // Get the specific parent which has the element with the class 'filters-fleet__item__body' and remove the class 'is-open'
      btn.parentElement.parentElement.parentElement
        .querySelector('.filters-fleet__item__body')
        .classList.remove('is-open');
    });
  });

  btnMetric.addEventListener('click', () => {
    // Only show the metric values
    tablesDesktop.forEach((table) => {
      table.classList.add('filters-fleet__table--metric');
      table.classList.remove('filters-fleet__table--imperial');
    });

    tablesMobile.forEach((table) => {
      table.classList.add('filters-fleet__mobile--metric');
      table.classList.remove('filters-fleet__mobile--imperial');
    });

    // Add and remove 'active' class from the buttons
    btnMetric.classList.add('btn--primary');
    btnMetric.classList.remove('btn--primary--outline');
    btnMetric.disabled = true;
    btnImperial.classList.remove('btn--primary');
    btnImperial.classList.add('btn--primary--outline');
    btnImperial.disabled = false;

    // Show only the items that contain the data-region attribute equal to 'europe' or 'europe northAmerica'
    showItemsByRegion('europe');
  });

  btnImperial.addEventListener('click', () => {
    // Only show the metric values
    tablesDesktop.forEach((table) => {
      table.classList.remove('filters-fleet__table--metric');
      table.classList.add('filters-fleet__table--imperial');
    });

    tablesMobile.forEach((table) => {
      table.classList.remove('filters-fleet__mobile--metric');
      table.classList.add('filters-fleet__mobile--imperial');
    });

    // Add and remove 'active' class from the buttons
    btnMetric.classList.remove('btn--primary');
    btnMetric.classList.add('btn--primary--outline');
    btnMetric.disabled = false;
    btnImperial.classList.add('btn--primary');
    btnImperial.classList.remove('btn--primary--outline');
    btnImperial.disabled = true;

    // Show only the items that contain the data-region attribute equal to 'northAmerica' or 'europe northAmerica'
    showItemsByRegion('northAmerica');
  });
};

export default filtersFleet;
