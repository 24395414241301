/**
 * Imports
 */
import {
    createGlideInstances,
    filtersFleet,
    on24Redirect,
    toggleAccordion,
} from './components';
import {
    dataLayers,
    debounce,
    domLoaded,
    initializeLocalStorage,
} from './utils';

import FormScroll from './components/formScroll';
import FormValidation from './components/formValidation';

/**
 * Update the address in the footer based on the selected country
 */
const updateFooterCountries = () => {
  // Select the elements
  const selectElement = document.querySelector('.footer__top__address__select');
  const textElement = document.querySelector('.footer__top__address__text');

  // Stop the function if the select element doesn't exist
  if (!selectElement) return;

  // Set the initial value in the text element
  textElement.innerHTML = selectElement.value;

  // Update the text element when the select element changes
  selectElement.addEventListener('change', () => {
    textElement.innerHTML = selectElement.value;
  });
};

/**
 * Adjust the header on scroll
 */
const adjustElementsOnScroll = () => {
  const navbarMainDesktop = document.querySelector('.navbar--main--desktop');
  const navbarTop = document.querySelector('.navbar--top');
  const anchorBlock = document.querySelector('.block--anchor');
  const scrollToTopBtn = document.querySelector('.btn--scroll-to-top');
  const languageBanner = document.querySelector('.language-banner');

  window.addEventListener(
    'scroll',
    debounce(() => {
      if (window.scrollY === 0) {
        navbarMainDesktop.classList.remove('is-scrolled');
        navbarTop.classList.remove('is-scrolled');
        scrollToTopBtn.classList.remove('is-scrolled');

        if (languageBanner) {
          languageBanner.classList.remove('is-scrolled');
        }

        if (anchorBlock) {
          anchorBlock.style.scrollMarginTop = '14rem';
        }
      }

      if (window.scrollY > 100) {
        navbarMainDesktop.classList.add('is-scrolled');
        navbarTop.classList.add('is-scrolled');
        scrollToTopBtn.classList.add('is-scrolled');

        if (anchorBlock) {
          anchorBlock.style.scrollMarginTop = '9rem';
        }
      }

      if (window.scrollY > 250) {
        if (languageBanner && languageBanner.style.display !== 'none') {
          languageBanner.classList.add('is-scrolled');
          document.body.classList.add('screen-darken-blur');
        }
      }
    })
  );
};

/**
 * Scroll to top button
 */
const scrollToTop = () => {
  const scrollToTopBtn = document.querySelector('.btn--scroll-to-top');

  if (!scrollToTopBtn) {
    return;
  }

  scrollToTopBtn.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });
};

/**
 * Toggle the header search bar on desktop (when scrolling)
 */
const toggleHeaderSearchBar = () => {
  const headerSearchBtn = document.querySelector('.btn--search');
  const headerSearchForm = document.querySelector('.expanded-search-form');

  if (!headerSearchBtn || !headerSearchForm) {
    return;
  }

  headerSearchBtn.addEventListener('click', () => {
    headerSearchForm.classList.toggle('is-open');
    headerSearchForm.querySelector('input').focus();
  });

  // When clicking outside the search form, close it
  document.addEventListener('click', (e) => {
    if (
      !headerSearchForm.contains(e.target) &&
      !headerSearchBtn.contains(e.target)
    ) {
      headerSearchForm.classList.remove('is-open');
    }
  });
};

/**
 * Toggle the mobile menu
 */
const toggleMobileMenu = () => {
  const mobileMenu = document.querySelector('.navbar--main--mobile__content');
  const mobileMenuToggle = document.querySelector(
    '.navbar--main--mobile__toggle-button #hamburger'
  );
  const btnExpand = document.querySelectorAll('.btn--expand');
  const btnCollapse = document.querySelectorAll('.btn--collapse');
  const passiveItems = document.querySelectorAll(
    '.nav-item-content-container > .nav-link-passive'
  );

  if (
    !mobileMenu ||
    !mobileMenuToggle ||
    !btnExpand ||
    !btnCollapse ||
    !passiveItems
  ) {
    return;
  }

  // If the checkbox is checked, add the is-open class to the mobile menu, if unchecked remove the class
  mobileMenuToggle.addEventListener('click', () => {
    if (mobileMenuToggle.checked) {
      mobileMenu.classList.add('is-open');
      document.body.style.overflow = 'hidden';
    } else {
      mobileMenu.classList.remove('is-open');
      document.body.style.overflow = 'auto';
    }
  });

  passiveItems.forEach((item) => {
    item.addEventListener('click', () => {
      const isOpen = item.parentElement
        .closest('.nav-item-content')
        .classList.contains('is-open');

      document.querySelectorAll('.nav-item-content').forEach((child) => {
        child.classList.remove('is-open');
      });
      btnCollapse.forEach((btn) => {
        btn.classList.add('is-hidden');
      });
      btnExpand.forEach((btn) => {
        btn.classList.remove('is-hidden');
      });

      if (!isOpen) {
        item.parentElement
          .closest('.nav-item-content')
          .classList.add('is-open');
        item.parentElement
          .querySelector('.btn--expand')
          .classList.add('is-hidden');
        item.parentElement
          .querySelector('.btn--collapse')
          .classList.remove('is-hidden');
      }
    });
  });

  btnExpand.forEach((btn) => {
    btn.addEventListener('click', () => {
      document.querySelectorAll('.nav-item-content').forEach((child) => {
        child.classList.remove('is-open');
      });
      btnCollapse.forEach((btn) => {
        btn.classList.add('is-hidden');
      });
      btnExpand.forEach((btn) => {
        btn.classList.remove('is-hidden');
      });

      btn.parentElement.closest('.nav-item-content').classList.add('is-open');
      btn.classList.add('is-hidden');
      btn.parentElement
        .querySelector('.btn--collapse')
        .classList.remove('is-hidden');
    });
  });

  btnCollapse.forEach((btn) => {
    btn.addEventListener('click', () => {
      btn.parentElement
        .closest('.nav-item-content')
        .classList.remove('is-open');
      btn.classList.add('is-hidden');
      btn.parentElement
        .querySelector('.btn--expand')
        .classList.remove('is-hidden');
    });
  });
};

/**
 * Desktop submenu height
 */
const setDesktopSubmenuHeight = () => {
  const desktopNavEl = document.querySelector('.nav--main--desktop');
  const levelOneNavItems = document.querySelectorAll(
    '.nav--main--desktop > .nav-list-parent > .nav-item'
  );
  const levelTwoNavItems = document.querySelectorAll(
    '.nav--main--desktop > .nav-list-parent > .nav-item > .nav-item-content > .nav-list-children-container > .nav-list-children > .nav-item'
  );

  if (!desktopNavEl || !levelTwoNavItems) {
    return;
  }

  levelOneNavItems.forEach((item) => {
    const rect = item.getBoundingClientRect();
    const childContainer = item.querySelector(
      '.nav-list-children-container > .nav-list-children'
    );

    if (childContainer) {
      childContainer.style.marginLeft = `${rect.x}px`;

      // On window resize update the margin-left of the child container
      window.addEventListener(
        'resize',
        debounce(() => {
          const rect = item.getBoundingClientRect();
          childContainer.style.marginLeft = `${rect.x}px`;
        })
      );
    }
  });

  levelTwoNavItems.forEach((item) => {
    const parentContainer = item.parentElement.closest(
      '.nav-list-children-container'
    );
    const child = item.querySelector('.nav-list-children-container');

    item.addEventListener('mouseenter', () => {
      closeAllSubmenus();
      parentContainer.style.height = 'auto';
      if (child) {
        child.classList.add('is-open');
        if (child.offsetHeight > parentContainer.offsetHeight) {
          parentContainer.style.height = `${child.offsetHeight}px`;
        } else {
          child.style.height = `${parentContainer.offsetHeight}px`;
        }
      }
    });

    item.addEventListener('mouseleave', () => {
      if (child && !child.classList.contains('is-open')) {
        parentContainer.style.height = 'auto';
      }
    });

    parentContainer.addEventListener('mouseleave', () => {
      if (child) {
        child.classList.remove('is-open');
      }
      parentContainer.style.height = 'auto';
    });
  });

  const closeAllSubmenus = () => {
    const submenus = document.querySelectorAll(
      '.nav--main .nav-list-children-container .nav-list-children-container'
    );
    submenus.forEach((submenu) => {
      submenu.classList.remove('is-open');
    });
  };
};

/**
 * Set the active anchor link in the aside
 */
const setActiveAnchorNavLink = () => {
  // Get all anchor links within the aside
  const anchorLinks = Array.from(
    document.querySelectorAll('.nav--aside ul li a')
  );

  // Get the top offset of each content anchor
  const contentAnchors = Array.from(
    document.querySelectorAll('.block--anchor')
  );
  const anchorOffsets = contentAnchors.map((anchor) => ({
    id: anchor.getAttribute('id'),
    offsetTop: anchor.offsetTop,
  }));

  // Debounce scroll event handler
  const debounceScroll = debounce(() => {
    const scrollPosition = window.pageYOffset + window.innerHeight / 2; // Calculate scroll position
    let activeAnchorId = null;

    // Find the active anchor based on the scroll position and content offsets
    anchorOffsets.forEach((anchor) => {
      if (scrollPosition >= anchor.offsetTop) {
        activeAnchorId = anchor.id;
      }
    });

    // Update the active anchor in the aside
    anchorLinks.forEach((link) => {
      const linkTargetId = link.getAttribute('href').substring(1); // Get the target ID from the anchor link

      if (linkTargetId === activeAnchorId) {
        link.classList.add('nav-link-active'); // Add the active class
      } else {
        link.classList.remove('nav-link-active'); // Remove the active class
      }
    });
  }, 10);

  // Debounced scroll event listener
  window.addEventListener('scroll', debounceScroll);
};

/**
 * Language switcher
 */
const toggleLanguageSwitcher = () => {
  const langSelectNav = document.querySelector('.nav--lang');
  const langSelectBtn = document.querySelector('.lang-select__button');

  if (!langSelectNav || !langSelectBtn) {
    return;
  }

  langSelectBtn.addEventListener('click', () => {
    langSelectNav.classList.toggle('is-open');
  });

  document.addEventListener('click', (e) => {
    if (!langSelectNav.contains(e.target)) {
      langSelectNav.classList.remove('is-open');
    }
  });
};

/**
 * Toggle the filters on mobile on the Knowledge Hub overview page
 */
const toggleFilters = () => {
  const filters = document.querySelector(
    '.knowledge-hub-filters__form__field__container'
  );
  const filtersToggle = document.querySelector(
    '.knowledge-hub-filters__form__mobile-filter-button'
  );
  const filtersClose = document.querySelector(
    '.knowledge-hub-filters__form__mobile-close-button'
  );
  const filtersForm = document.querySelector('.knowledge-hub-filters__form');

  if (filters) {
    filtersToggle.addEventListener('click', () => {
      filtersForm.classList.add('is-open');
      document.body.classList.add('screen-darken');
    });

    filtersClose.addEventListener('click', () => {
      filtersForm.classList.remove('is-open');
      document.body.classList.remove('screen-darken');
    });
  }
};

/**
 * Update the knowledge hub select field with the number of selected filters/options
 */
const updateKnowledgeHubDropdownCounters = () => {
  const dropdowns = document.querySelectorAll(
    '.knowledge-hub-filters__form .form-field-dropdown'
  );

  if (!dropdowns) return;

  dropdowns.forEach((dropdown) => {
    const counterSpan = dropdown.querySelector('.selectedCount');
    const dropdownList = dropdown.querySelector('.form-field-dropdown__list');

    if (!dropdownList) return;

    // Add event listener to each checkbox
    const checkboxes = dropdownList.querySelectorAll('input[type="checkbox"]');

    const updateCounter = () => {
      const checkedCheckboxes = dropdownList.querySelectorAll(
        'input[type="checkbox"]:checked'
      );
      counterSpan.textContent = checkedCheckboxes.length;
    };

    // Set the initial counter text to the number of selected checkboxes
    updateCounter();

    // Look for changes in the checkboxes and update the counter text
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', updateCounter);
    });
  });
};

/**
 * Remove the anchor sidebar when scrolling past the full content width block
 */
const removeAnchorSideBar = () => {
  const fullWidthContent = document.querySelector(
    '.page--content-with-sidebar--full'
  );
  const navAside = document.querySelector('.nav--aside');

  if (fullWidthContent) {
    window.addEventListener(
      'scroll',
      debounce(() => {
        const { y } = fullWidthContent.getBoundingClientRect();

        y <= 450
          ? navAside.classList.add('js-hide')
          : navAside.classList.remove('js-hide');
      }, 10)
    );
  }
};

/**
 * Create a 'fake' select form field with a dropdown menu and checkboxes
 */
const createSelectFormField = () => {
  const dropdownToggles = document.querySelectorAll('.form-field-dropdown');

  dropdownToggles.forEach((dropdownToggle) => {
    const dropdownMenu = dropdownToggle.querySelector(
      '.form-field-dropdown__list'
    );

    dropdownToggle.addEventListener('click', () => {
      dropdownToggle.classList.toggle('open');
    });

    document.addEventListener('click', (e) => {
      if (
        !dropdownToggle.contains(e.target) &&
        !dropdownMenu.contains(e.target)
      ) {
        dropdownToggle.classList.remove('open');
      }
    });

    // Prevent checkbox clicks from closing the dropdown
    dropdownMenu.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  });
};

/**
 * Update time zones based on the selected option.
 */
const updateTimeZones = () => {
  // Select the elements
  const selectElement = document.getElementById('timezone-select');
  const textElement = document.querySelector('.selected-timezone');

  // Stop the function if the select element doesn't exist
  if (!selectElement) return;

  // Set the initial value in the text element
  textElement.innerText = selectElement.value;

  // Update the text element when the select element changes
  selectElement.addEventListener('change', () => {
    textElement.innerText = selectElement.value;
  });
};

/**
 * Render the embedded video iframe
 */
const renderIFrame = () => {
  const videoEmbedContainers = document.querySelectorAll(
    '.video-embed-container'
  );

  if (!videoEmbedContainers) {
    return;
  }

  videoEmbedContainers.forEach((videoEmbedContainer) => {
    const imgPlaceholder = videoEmbedContainer.querySelector(
      '.video-embed-thumbnail'
    );
    const iframeContainer = videoEmbedContainer.querySelector('.video-embed');
    const iframeAspect = videoEmbedContainer.querySelector(
      '.video-embed-aspect'
    );

    const dataUrl = imgPlaceholder.getAttribute('data-url');

    const iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      `https://youtube.com/embed/${dataUrl}?autoplay=1&rel=0`
    );
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', 'true');
    iframe.setAttribute('allow', 'autoplay; fullscreen');

    // When clicking the placeholder, remove the placeholder and show the iframe
    imgPlaceholder.addEventListener('click', () => {
      iframeAspect.appendChild(iframe);
      imgPlaceholder.classList.add('js-hide');
      iframeContainer.classList.remove('js-hide');
    });
  });
};

const initDynamicForms = () => {
    document.querySelectorAll('.block--form form, .form form').forEach(form => {
        new FormScroll({ form });
        new FormValidation({ form });
    });
};

/**
 * Load the functions when the DOM is loaded
 */
domLoaded(() => {
  adjustElementsOnScroll();
  createGlideInstances();
  createSelectFormField();
  dataLayers();
  filtersFleet();
  on24Redirect();
  removeAnchorSideBar();
  renderIFrame();
  scrollToTop();
  setActiveAnchorNavLink();
  toggleAccordion();
  toggleFilters();
  toggleHeaderSearchBar();
  toggleLanguageSwitcher();
  toggleMobileMenu();
  updateFooterCountries();
  updateKnowledgeHubDropdownCounters();
  updateTimeZones();
  initDynamicForms();

  initializeLocalStorage();
  setDesktopSubmenuHeight();
  window.addEventListener('load', () => {
    setDesktopSubmenuHeight();
  });
});
