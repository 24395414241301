/**
 * ON24 Redirect
 */
const on24Redirect = () => {
  const on24Form = document.querySelector('form[data-handle="on24form"]');

  // Stop the function if the form doesn't exist on the page
  if (!on24Form) return;

  // Gated content redirect logic
  on24Form.addEventListener('freeform-ajax-success', (e) => {
    const response = e.response;
    const redirectUrl = response.on24RedirectUrl;

    if (redirectUrl) {
      window.open(redirectUrl, "_blank");
    }
  });
};

export default on24Redirect;
