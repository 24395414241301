/**
 * Accordion dropdown
 */
const toggleAccordion = () => {
  const accordionItems = document.querySelectorAll('.accordion__list__item');

  accordionItems.forEach((item, index) => {
    const accordionTop = item.querySelector('.accordion__list__item__top');

    // Open the first accordion item by default
    if (index === 0) {
      item.classList.add('active');
    }

    // Toggle the accordion item
    accordionTop.addEventListener('click', () => {
      window.dataLayer = window.dataLayer || [];

      if (item.classList.contains('active')) {
        item.classList.remove('active');
        window.dataLayer.push({
          event: 'click_faq',
          question_open: 'unsuccessful',
        });
      } else {
        item.classList.add('active');
        window.dataLayer.push({
          event: 'click_faq',
          question_open: 'successful',
          faq_title: item.querySelector('.accordion__list__item__top__title')
            .innerHTML,
        });
      }
    });
  });
};

export default toggleAccordion;
