/**
 * Import the Glide library
 */
import Glide from '@glidejs/glide';

/**
 * Create Glide instances when the glide class is present in one or multiple elements
 */
const createGlideInstances = () => {
  // Select the glide elements
  const textImageCarouselGlides = document.querySelectorAll(
    '.text-image-carousel-glide'
  );
  const cardsGlides = document.querySelectorAll('.cards-glide');
  const testimonialsGlides = document.querySelectorAll('.testimonials-glide');
  const circularProcessGlides = document.querySelectorAll(
    '.circular-process-glide'
  );

  // Cards glide
  if (cardsGlides) {
    cardsGlides.forEach((glide) => {
      const glideInstance = new Glide(glide, {
        bound: true,
        breakpoints: {
          768: {
            perView: 1,
          },
          992: {
            perView: 2,
          },
        },
        perView: Number(glide.dataset.columns) || 3,
        gap: 16,
      });

      glideInstance.mount();
    });
  }

  // Circular process glide
  if (circularProcessGlides) {
    circularProcessGlides.forEach((glide) => {
      const glideInstance = new Glide(glide, {
        bound: true,
        perView: 1,
        startAt: 0,
        dragThreshold: false,
      });

      glideInstance.mount();
    });
  }

  // Testimonials glide
  if (testimonialsGlides) {
    // Create 2 instances that are in sync with each other, the first one is the main one, it has 1 slide per view and is bigger than the other. The second one will show 2 smaller ones per view.
    testimonialsGlides.forEach((glide) => {
      const glideInstance = new Glide(glide, {
        bound: true,
        breakpoints: {
          768: {
            perView: 1
          },
          992: {
            perView: 2,
          },
        },
        perView: 2,
        gap: 16,
      });

      glideInstance.mount();
    });
  }

  // Text image carousel glide
  if (textImageCarouselGlides) {
    textImageCarouselGlides.forEach((glide) => {
      const glideInstance = new Glide(glide, {
        bound: true,
        perView: 1,
      });

      glideInstance.mount();
    });
  }
};

export default createGlideInstances;
