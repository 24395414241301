class FormValidation {
    constructor ({ form }) {
        this.form = form;
        this.inputs = [...this.form.querySelectorAll('input, textarea, select')];
        this.submitButton = this.form.querySelector('button[type="submit"]');
        this.requiredMessage = this.form.getAttribute('data-required-message');
        this.emailMessage = this.form.getAttribute('data-email-message');

        if (this.inputs.length === 0 || !this.submitButton) return;

        this.inputs.forEach(input => {
            this.validateInput(input);
            input.addEventListener('blur', () => { 
                this.addTouchedClass(input);
                input.dataset.hasBlurred = 'true';
                this.validateInput(input);
            });

            input.addEventListener('input', () => { this.validateInput(input); });
        });
    }

    validateInput = (input) => {
        const errorType = this.checkInputValidity(input);
        const errorContainer = input.parentNode.querySelector('.freeform-errors');

        if (errorType) {
            this.showValidationMessage(input, errorContainer, errorType);
            input.classList.add('is-invalid');
        } else {
            input.classList.remove('is-invalid');
            errorContainer?.remove();
        }

        this.checkFormValidity();
    };

    checkInputValidity = (input) => {
        if (input.closest('.form__row__field--required') && (!input.value || (input.type === 'checkbox' && !input.checked))) {
            return 'required';
        } else if (input.type === 'email' && input.value && !this.isValidEmail(input.value)) {
            return 'invalidEmail';
        } else {
            return null;
        }
    };

    checkFormValidity = () => {
        const isFormInvalid = this.inputs.some(input => input.classList.contains('is-invalid'));

        if (isFormInvalid) {
            this.submitButton.setAttribute('disabled', 'disabled');
        } else {
            this.submitButton.removeAttribute('disabled');
        }
    };

    isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    addTouchedClass = (input) => {
        input.classList.add('is-touched');
    };

    showValidationMessage = (input, errorContainer, errorType) => {
        if (input.dataset.hasBlurred !== 'true') return;

        const messages = {
            required: this.requiredMessage ?? 'This field is required',
            invalidEmail: this.emailMessage ?? 'Please enter a valid email address'
        }

        if (errorContainer) {
            errorContainer.querySelector('li').textContent = messages[errorType];
            return;
        }
        

        let ul = document.createElement('ul');
        ul.classList.add('freeform-errors');
        let li = document.createElement('li');
        li.textContent = messages[errorType];
        ul.appendChild(li);

        input.parentNode.appendChild(ul);
    };
}

export default FormValidation;
