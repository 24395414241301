class FormScroll {
    constructor ({ form }) {
        this.form = form;

        this.form.addEventListener('submit', this.onSubmit);
    }

    onSubmit = (event) => {
        const target = event.currentTarget;

        target.scrollIntoView({
            alignToTop: true
        });
    };
}

export default FormScroll;
