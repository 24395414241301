const closeLanguageBanner = () => {
  const banner = document.querySelector('.language-banner');
  const btnClose = document.querySelector('.language-banner__continue-btn');

  if (!banner || !btnClose) {
    return;
  }

  // Check if the banner has been closed before
  const isBannerClosed = localStorage.getItem('languageBannerClosed');
  const closedTimestamp = localStorage.getItem('languageBannerClosedTimestamp');

  if (isBannerClosed && closedTimestamp) {
    const halfADayInMilliseconds = 12 * 60 * 60 * 1000;
    const currentTime = Date.now();
    const timeSinceClosed = currentTime - parseInt(closedTimestamp);

    // If the banner has been closed before and it's been more than one day, delete the item
    if (timeSinceClosed >= halfADayInMilliseconds) {
      localStorage.removeItem('languageBannerClosed');
      localStorage.removeItem('languageBannerClosedTimestamp');
    } else {
      // If the banner has been closed before but it's within one day, hide it
      banner.style.display = 'none';
      return;
    }
  }

  // If the banner hasn't been closed before or it's been more than one day, show it
  banner.style.display = 'flex';

  // Function to handle the close button click event
  const handleBtnContinueClick = () => {
    // Hide the banner
    document.body.classList.remove('screen-darken-blur');
    banner.style.display = 'none';
    banner.classList.remove('is-scrolled');

    // Store the information in local storage
    localStorage.setItem('languageBannerClosed', 'true');
    localStorage.setItem(
      'languageBannerClosedTimestamp',
      Date.now().toString()
    );
  };

  // Add the event listener to the close button
  btnClose.addEventListener('click', handleBtnContinueClick);
};

const initializeLocalStorage = () => {
  closeLanguageBanner();
};

export default initializeLocalStorage;
