/**
 * Forms dataLayer tracking
 */
const formsDataLayer = (form) => {
  // Stop the function if the form doesn't exist on the page
  if (!form) return;

  // Get the form's attributes
  const formId = form.id;
  const formName = form.getAttribute('data-name');
  const formType = form.getAttribute('data-type');
  const formUrl = window.location.href;

  // Data layer push function
  const dataLayerPush = (event, wasSuccessful) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      form_id: formId,
      form_name: formName,
      form_type: formType,
      form_url: formUrl,

      // If the event is 'form_submit', and there is a wasSuccessful parameter and push it to the data layer
      ...(event === 'form_submit' &&
        wasSuccessful !== undefined && {
          wasSuccessful: wasSuccessful ? 'Successful' : 'Unsuccessful',
        }),
    });
  };

  // When starting filling in the form, already send an event to Google Analytics
  form.addEventListener('focusin', function handler() {
    // Send the event to Google Analytics
    dataLayerPush('form_start');

    // Unbind the event listener after the first time it's triggered
    form.removeEventListener('focusin', handler);
  });

  // Freeform AJAX form: Send the event to Google Analytics when the form is submitted successfully
  form.addEventListener('freeform-ajax-success', () => {
    // Send the event to Google Analytics
    dataLayerPush('form_submit', true);
  });

  // Freeform AJAX form: Send the event to Google Analytics when the form is submitted unsuccessfully
  form.addEventListener('freeform-ajax-error', () => {
    // Send the event to Google Analytics
    dataLayerPush('form_submit', false);
  });
};

// Contact form tracking
const contactForm = document.querySelector('form[data-handle="contactForm"]');
formsDataLayer(contactForm);

// ON24 form tracking
const on24form = document.querySelector('form[data-handle="on24form"]');
formsDataLayer(on24form);

/**
 * Knowledge hub dataLayer tracking
 */
const knowledgeHubDataLayer = () => {
  const knowledgeHubFiltersForm = document.querySelector(
    '.knowledge-hub-filters__form'
  );
  const searchTerm = document.querySelector(
    '.knowledge-hub-filters__form__field input[type="search"]'
  );
  const languageFilter = document.querySelector('.languageFilter');
  const industryFilter = document.querySelector('.industryFilter');
  const purificationFilter = document.querySelector('.purificationNeedFilter');
  const knowledgeHubSubmitButton = document.querySelector(
    '#gtm-show-filter-results'
  );

  if (!knowledgeHubFiltersForm) return;

  // Language filter dataLayer tracking
  languageFilter.addEventListener('change', () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'filter_knowledge_hub',
      filter_category: 'language',
      filter_value: languageFilter.options[languageFilter.selectedIndex].label,
      filter_type: 'knowledge hub',
    });
  });

  // Industry filter dataLayer tracking
  industryFilter.querySelectorAll('li').forEach((li) => {
    li.querySelector('input[type="checkbox"]').addEventListener(
      'change',
      () => {
        if (li.querySelector('input').checked) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'filter_knowledge_hub',
            filter_category: 'industry',
            filter_value: li.querySelector('input').getAttribute('data-label'),
            filter_type: 'knowledge hub',
          });
        }
      }
    );
  });

  // Purification filter dataLayer tracking
  purificationFilter.querySelectorAll('li').forEach((li) => {
    li.querySelector('input[type="checkbox"]').addEventListener(
      'change',
      () => {
        if (li.querySelector('input').checked) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'filter_knowledge_hub',
            filter_type: 'knowledge hub',
            filter_category: 'purification need',
            filter_value: li.querySelector('input').getAttribute('data-label'),
          });
        }
      }
    );
  });

  knowledgeHubSubmitButton.addEventListener('click', () => {
    const purificationFilterValues = Array.from(
      purificationFilter.querySelectorAll('input:checked')
    ).map((input) => input.getAttribute('data-label'));

    const industryFilterValues = Array.from(
      industryFilter.querySelectorAll('input:checked')
    ).map((input) => input.getAttribute('data-label'));

    const filterValues = [
      languageFilter.options[languageFilter.selectedIndex].label,
      purificationFilterValues.join(', '),
      industryFilterValues.join(', '),
    ].join(' | ');

    // Send all of the selected values to GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'filter_knowledge_hub_submit',
      filter_type: 'knowledge hub',
      filter_category: `language${
        purificationFilterValues.length ? ' | purification need' : ''
      }${industryFilterValues.length ? ' | industry' : ''}`,
      filter_value: filterValues,

      // If there is a keyword in the form-field knowledge-hub-filters__form__field search input, push it to the data layer
      ...(searchTerm &&
        searchTerm.value && {
          search_type: 'knowledge hub search',
          search_term: searchTerm.value,
        }),
    });
  });
};

/**
 * Search dataLayer tracking
 */
const searchDataLayer = () => {
  // Search header forms
  const headerSearchForms = document.querySelectorAll('.header .search-form');
  const knowledgeHubSearchBtn = document.querySelector('.knowledge-hub-filters__form__field .search-field__container button[type="submit"]');

  if (!headerSearchForms) {
    return;
  } else {
    headerSearchForms.forEach((form) => {
      form.addEventListener('submit', () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'view_search_results',
          search_term: form.querySelector('input[type="search"]').value,
          search_type: 'search box in header',
        });
      });
    });
  }

  if (!knowledgeHubSearchBtn) {
    return;
  } else {
    knowledgeHubSearchBtn.addEventListener('click', () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: document.querySelector('.knowledge-hub-filters__form__field .search-field__container input[type="search"]').value,
        search_type: 'search box in knowledge hub',
      });
    });
  };
};

/**
 * Local presence dataLayer tracking
 */
const localPresenceDataLayer = () => {
  const localPresenceFiltersForm = document.querySelector(
    '.footer__top__address__select'
  );
  localPresenceFiltersForm.addEventListener('change', () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'filter_local_presence',
      filter_value:
        localPresenceFiltersForm.options[localPresenceFiltersForm.selectedIndex]
          .label,
      filter_type: 'local presence',
      page_url: window.location.href,
    });
  });
};

/**
 * Import all dataLayers and export them as a single function
 */
const dataLayers = () => {
  formsDataLayer();
  knowledgeHubDataLayer();
  localPresenceDataLayer();
  searchDataLayer();
};

export default dataLayers;
